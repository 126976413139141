@import url("bootstrap-utilities.css");
/* =======================================
	1. GENERAL
	-- 1.1 Heading
	-- 1.2 Text
  -- 1.3 Tab
  -- 1.4 Breadcrumb

	2. FORM
	-- 2.1 Button
	-- 2.2 Select
	-- 2.3 Picker
	-- 2.4 Input

	3. MODAL

	4. MAIN CONTENT
	-- 4.1 Subheader
  -- 4.2 Page Content

  5. PLUGINS
  -- 5.1 Datepicker
  -- 5.2 Tag Input
  -- 5.3 Switch
  -- 5.4 Timepicker
  -- 5.5 React-image-crop
  -- 5.6 Table
  -- 5.7 Date Range Picker
======================================= */

/*===== 1. GENERAL =====*/
html {
  word-break: break-word;
}
.hidden {
  display: none !important;
}
.clickable {
  cursor: pointer !important;
}
@media only screen and (max-width: 767px) {
  .isoLayoutContentWrapper > div,
  .isoLayoutContentWrapper > .isoLayoutContent {
    padding: 10px;
  }
}

.flex-1 {
  flex: 1;
}

.image-full {
  width: 100%;
  height: 100%;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1001;
}

/*----- 1.2 Text -----*/
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-success {
  color: #02c498;
}

.text-danger {
  color: #ef4c66;
}
.text-strikethrough {
  text-decoration: line-through;
}

.text-muted {
  font-size: 80%;
  margin-top: 5px;
  display: block;
}

.text-bold {
  font-weight: 900;
}

.lineHeight1 {
  line-height: 1;
}
/*----- 1.2 Text -----*/

/*----- 1.3 Tab -----*/
.ant-tabs-ink-bar {
  background-color: #fd9648;
}
/*----- END 1.3 Tab -----*/
/*----- 1.4 Breadcrumb -----*/
.breadcrumb-wrapper {
  background: #ffffff;
  width: 100%;
  padding: 8px 15px;
  margin-bottom: 15px;
}
.breadcrumb-wrapper .ant-breadcrumb-link a {
  color: #fd9648;
}
/*----- END 1.4 Breadcrumb -----*/

/*===== END 1. GENERAL =====*/

/*===== 2. FORM =====*/
.ant-form .ant-form-item {
  margin-bottom: 15px;
  padding-bottom: 0;
}
.ant-form .ant-form-item label {
  line-height: 1.2;
}
.ant-form .ant-form-item-label label {
  margin-bottom: 8px;
}
.ant-form .ant-form-item-label label,
.ant-radio-group .ant-radio-wrapper {
  color: #949494;
  text-transform: uppercase;
  font-size: 12px;
}
.ant-form .ant-form-item .DateInput {
  font-size: 14px;
  width: 180px;
}
.ant-form .ant-form-item .DateInput_input {
  padding: 2px 8px;
  font-size: 14px;
}
.ant-form .ant-form-item .DateInput .DateInput_input__focused {
  border-bottom: 2px solid #fd9648;
}
.ant-form textarea.ant-input {
  margin-bottom: 0;
}

.help-error {
  font-size: 12px;
  color: #f5222d;
}

/*----- 2.1 Button -----*/
/* Buttons */
button[type="button"].ant-btn {
  text-transform: uppercase;
  font-weight: 400;
}
button[type="button"].ant-btn.ant-btn-sm {
  height: 30px;
}
.btn-success {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
  color: #ffffff !important;
}
/*----- END 2.1 Button -----*/

/*----- 2.2 Select -----*/
.ant-select {
  width: 100%;
}
.ant-select .ant-select-selection--single {
  height: 35px;
  border: 1px solid #e9e9e9;
}
.ant-select-dropdown-menu-item {
  line-height: 1.2;
}
.ant-select-dropdown-menu-item-group-title {
  height: auto;
  line-height: 1.2;
  padding: 5px 12px;
}
.ant-select-dropdown-menu-item-group {
  list-style-type: none;
}
.ant-select-dropdown-menu-item-group
  > .ant-select-dropdown-menu-item-group-title,
.ant-select-dropdown-menu-item-group
  > .ant-select-dropdown-menu-item-group-list {
  padding-left: 10px;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 10px;
}
/*----- END 2.2 Select -----*/

/*----- 2.3 Picker -----*/
.ant-input-group .ant-calendar-picker-input {
  height: 35px;
  border: 1px solid #e9e9e9;
}
/*----- END 2.3 Picker -----*/

/*----- 2.4 Input -----*/
/*----- END 2.4 Input -----*/
/*===== END 2. FORM =====*/

/*===== 3. MODAL =====*/
.ant-modal-title {
  font-size: 20px;
  text-transform: uppercase;
  color: #707070;
}
.ant-modal .DateInput {
  font-size: 14px;
  width: 180px;
}
.ant-modal .DateInput_input {
  padding: 2px 8px;
  font-size: 14px;
}
.ant-modal .DateInput .DateInput_input__focused {
  border-bottom: 2px solid #fd9648;
}
/*===== END 3. MODAL =====*/

/*===== 5. PLUGINS =====*/
/*----- 5.1 Datepicker -----*/
/* Date Picker */
.DayPicker .CalendarDay__selected,
.DayPicker .CalendarDay__selected:active,
.DayPicker .CalendarDay__selected:hover {
  background: #fd9648;
  border: 1px double #fd9648;
}
.DayPicker .CalendarDay__selected_span {
  background: #fec193;
  border: 1px double #ffeddf;
}
.DayPicker .CalendarDay__selected_span:active,
.DayPicker .CalendarDay__selected_span:hover,
.DayPicker .CalendarDay__hovered_span,
.DayPicker .CalendarDay__hovered_span:hover {
  background: #ffeddf;
  border: 0;
  color: #fd9648;
  border: 1px double #ffeddf;
}
.DayPicker .CalendarDay__default:hover {
  background: #ffeddf;
  color: #fec193;
}
.DayPicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
/*.isoStickerWidget {
  height: 150px;
}*/
.ant-form-item .ant-calendar-picker {
  width: 100% !important;
}
.ant-form-item .ant-calendar-picker .ant-calendar-picker-input {
  height: 35px;
  border: 1px solid #e9e9e9;
}
/*----- END 5.1 Datepicker -----*/
/*----- 5.2 Tag Input -----*/
.react-tags-wrapper input {
  padding: 4px 10px;
  width: 100%;
  height: 35px;
  cursor: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  color: #595959;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-transition: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.react-tags-wrapper input:focus {
  outline: 0;
  border-color: #fd9648;
  box-shadow: 0 0 0 2px rgba(255, 237, 223, 0.2);
}
.react-tags-wrapper input::placeholder {
  color: #b4b4b4;
}

.react-tags-wrapper input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b4;
}

.react-tags-wrapper input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b4b4b4;
}

.react-tags-wrapper .tag-wrapper {
  border: 1px solid #fd9648;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
}

.react-tags-wrapper .tag-wrapper > a {
  color: #fd9648;
  margin-left: 5px;
}
/*----- END 5.2 Tag Input -----*/
/*----- 5.3 Switch -----*/
.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
/*----- END 5.3 Switch -----*/
/*----- 5.4 Timepicker -----*/
.ant-form-item .ant-time-picker {
  display: block;
  width: 100%;
}
/*----- END 5.4 Timepicker -----*/

/*----- 5.5 React-image-crop -----*/
.ant-modal-content .ReactCrop {
  background-color: transparent;
}
.ant-modal .ReactCrop__image {
  max-height: 100%;
}
/*----- END React-image-crop -----*/

/*----- 5.6 Table -----*/
.ant-table-footer .table-footer {
  margin-left: auto;
  width: auto;
}
.ant-table-footer .table-footer td {
  padding: 0 4px;
  text-align: right;
}
.ant-table-footer .table-footer td.label {
  font-weight: 500;
}
/*----- END 5.6 Table -----*/

/*----- 5.7 Date Range Picker -----*/
.DateRangePickerInput .DateRangePicker_picker {
  z-index: 10;
}
.DateRangePicker .container-custom-info {
  padding: 0.5rem;
}
.DateRangePicker .container-custom-info button {
  margin-bottom: 3px;
  margin-right: 3px;
}
/*----- END 5.7 Date Range Picker -----*/

/*===== END 5. PLUGINS =====*/
